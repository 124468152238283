import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Moment from "moment"
import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"
Moment.locale("en")

const AthleteEventDetailPage = ({ data }) => {
  const page = data.wagtail.page
  let ancestor = { children: [], button: {} }
  data.wagtail.page.ancestors.forEach(item => {
    if ("children" in item) {
      ancestor = item
    }
  })

  return (
    <Layout
      isContent={true}
      subMenu={ancestor.children}
      button={ancestor.button}
      parent={"athletes"}
      siteTitle={page.ancestors[0].title}
    >
      <section className="breadcrumb-area breadcrumb-sub-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/athletes/"> Athletes</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/athletes/${page.ancestors[0].slug}`}>
                      {page.ancestors[0].title}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-area event">
        <div className="tab-content-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="contact">
                      <h1>{page.eventname}</h1>
                      <p>{page.descriptionSimple}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-7">
                    <div className="evrnt-logation">
                      {(page.initialdate || page.enddate) && (
                        <div className="single-address">
                          <h6>Date</h6>
                          <p>
                            {page.initialdate &&
                              Moment(page.initialdate).format("MMM D, YYYY")}
                            {" - "}
                            {page.enddate &&
                              Moment(page.enddate).format("MMM D, YYYY")}
                          </p>
                        </div>
                      )}
                      {(page.initialtime || page.endtime) && (
                        <div className="single-address">
                          <h6>Time</h6>
                          <p>
                            {page.initialtime &&
                              Moment(page.initialtime).format("hh:mm A")}
                            {" - "}
                            {page.endtime &&
                              Moment(page.endtime).format("hh:mm A")}
                          </p>
                        </div>
                      )}
                      {page.numberofatendees && page.numberofatendees !== "0" && (
                        <div className="single-address">
                          <h6>Number of attendees</h6>
                          <p>{page.numberofatendees} Athletes</p>
                        </div>
                      )}
                      {page.ages && (
                        <div className="single-address">
                          <h6>Ages</h6>
                          <p>{page.ages} years old</p>
                        </div>
                      )}
                      {page.fee && (
                        <div className="single-address">
                          <h6>Fee</h6>
                          <p>${page.fee}</p>
                        </div>
                      )}
                      {page.locationname && (
                        <div className="single-address">
                          <h6>Location</h6>
                          <p>
                            {page.locationname} <br />
                          </p>
                        </div>
                      )}
                      {page.contact && (
                        <div className="single-address">
                          <h6>Contact</h6>
                          <p>{page.contact}</p>
                        </div>
                      )}
                      {page.host && (
                        <div className="single-address">
                          <h6>Host</h6>
                          <p>{page.host}</p>
                        </div>
                      )}
                      {page.hostwebsite && (
                        <div className="single-address">
                          <h6>Host Website</h6>
                          <Link to={page.hostwebsite}>Click Here</Link>
                        </div>
                      )}
                      {(page.selectioncriteria || page.eventdescription) && (
                        <div className="single-address">
                          <h6>Selection criteria</h6>
                          <p>
                            {page.selectioncriteria}
                            {page.selectioncriteria && <br />}
                            {page.eventdescription}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-5">
                    {page.locationaddress && page.imageMaps && (
                      <div className="map">
                        <a
                          href={page.locationaddress}
                          target="_blank"
                        >
                          <img src={`https://admin.swimontario.com/${page.imageMaps}`} />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="c-title-content mb-80 athlete-detail"
                      dangerouslySetInnerHTML={{
                        __html: page.bodySafe,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {data.wagtail.page.fullContent &&
        data.wagtail.page.fullContent.length > 0 && (
          <CTABlock data={data.wagtail.page.fullContent[0]} />
        )}
    </Layout>
  )
}
AthleteEventDetailPage.propTypes = {
  data: PropTypes.object.isRequired,
}
export const query = graphql`
  query($id: Int) {
    wagtail {
      page(id: $id) {
        ... on EventPage {
          id
          descriptionSimple
          bodySafe
          eventname
          initialdate
          initialtime
          enddate
          endtime
          numberofatendees
          ages
          fee
          locationname
          locationaddress
          imageMaps
          contact
          selectioncriteria
          eventdescription
          isProtected
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
          ancestors(order: "-depth") {
            title
            depth
            slug
            ... on AthletesPage {
              children {
                title
                slug
                ... on AthletePage {
                  live
                  isProtected
                }
                ... on EventPage {
                  live
                  isProtected
                }
                ... on SimplePage {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
          }
        }
      }
    }
  }
`
export default AthleteEventDetailPage
